import {
  ConfirmationDialog,
  OverflowMenu,
  Tooltip,
  useModalContext,
} from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Action } from 'store/modules/actions'
import { successToast } from '../../utils/success-toast'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { errorToast } from '../../utils/error-toast'
import { store } from 'store/index'

export type ActionOverflowProps = {
  action: Action
  source: string
}

export const ActionOverflow = observer((props: ActionOverflowProps) => {
  const { action, source } = props

  const { openModal } = useModalContext()

  const disabled =
    !action.user.isCurrentUser &&
    !action.creator.isCurrentUser &&
    !store.currentUser?.isAdmin

  const onClick = React.useCallback(() => {
    openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
      resourceId: action.id,
      resourceType: 'Action',
      source,
      user: action.user,
      tabs: ['action'],
    })
  }, [action, openModal, source])

  const onConfirm = React.useCallback(async () => {
    const result = await action.destroy()
    if (result?.success) {
      successToast('Action deleted!')
    } else {
      errorToast()
    }
  }, [action])

  return (
    <ConfirmationDialog.Root
      body="This will permanently remove this Action and any connected data from Progression."
      onConfirm={onConfirm}
      title="Are you sure?"
    >
      <OverflowMenu.Root className="h-4 w-4" right>
        <Tooltip
          content={`Can only be edited or deleted by Action user or author`}
          disabled={!disabled}
        >
          <OverflowMenu.Item onClick={onClick} disabled={disabled}>
            Edit
          </OverflowMenu.Item>
          <ConfirmationDialog.Trigger asChild disabled={disabled}>
            <OverflowMenu.Item variant={!disabled ? 'destructive' : 'default'}>
              Delete
            </OverflowMenu.Item>
          </ConfirmationDialog.Trigger>
        </Tooltip>
      </OverflowMenu.Root>
    </ConfirmationDialog.Root>
  )
})
